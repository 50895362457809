import { configureStore } from "@reduxjs/toolkit";
import { transcriptReducer } from "./reducers/transcriptReducer";
import { topicsReducer } from "./reducers/topicReducer";
import { extractReducer } from "./reducers/extractReducer";
import { dashboardReducer } from "./reducers/dashboardReducer";
// import { snackBarReducer } from "./reducers/snackbarReducer";
import { userReducer } from "./reducers/userReducer";
import { fixReducer } from "./reducers/fixReducer";
import { integrationReducer } from "./reducers/integrationReducer";
import { leadsReducer } from "./reducers/leadsReducer";
import { agentReducer } from "./reducers/agentReducer";


import snackBarReducer  from "./slices/snackBarSlice";

export const store = configureStore({
    reducer: {
        transcripts: transcriptReducer,
        topics: topicsReducer,
        extracts: extractReducer,
        dashboard: dashboardReducer,
        snackbar: snackBarReducer,
        users: userReducer,
        fixes: fixReducer,
        integrations: integrationReducer,
        leads: leadsReducer,
        agent: agentReducer
    }
})