import React from "react";
import { Snackbar, Alert } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
// import { hideSnackBar } from "src/redux/actions/snackbarActions";
import { hideSnackBar } from "redux/slices/snackBarSlice";

const SnackBar = () => {
    const dispatch = useDispatch();
    const { show, message, severity } = useSelector((state) => state.snackbar);

    const handleClose = () => {
        dispatch(hideSnackBar());
    };

    return (
        <Snackbar 
            open={show} 
            autoHideDuration={3000} 
            onClose={() => handleClose()} 
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right' 
            }}
            sx={{
                '& .MuiSnackbarContent-root': {
                    minWidth: '400px',
                    fontSize: '1.1rem'
                }
            }}
        >
            <Alert
                onClose={() => handleClose()}
                severity={severity}
                variant="filled"
                sx={{ 
                    width: '100%',
                    '& .MuiAlert-message': {
                        fontSize: '1.1rem',
                        padding: '10px 0',
                        display: 'flex',
                        alignItems: 'center'
                    },
                    '& .MuiAlert-icon': {
                        fontSize: '28px',
                        marginRight: '12px',
                        padding: '0',
                        display: 'flex',
                        alignItems: 'center'
                    },
                    '& .MuiAlert-action': {
                        padding: '0 8px',
                        marginRight: '0',
                        display: 'flex',
                        alignItems: 'center'
                    },
                    '& .MuiButtonBase-root': {
                        padding: '8px'
                    }
                }}
            >
                {message}
            </Alert>
        </Snackbar>
    );
};

export default SnackBar;