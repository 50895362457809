import { GETCONVERSATIONS, GETONETRANSCRIPT, CLEANALLCONVERSATIONS, CLEANUPCONVERSATION, GETSALESTRANSCRIPTS, CLEANALLSALESCONVERSATIONS } from "redux/actions/transcriptActions";
import { produce } from "immer";

const initialState = {
    conversations: null,
    count: null,
    transcript: null,
    salesTranscripts: null,
    salesTranscriptsCount: null
};

export const transcriptReducer = (state = initialState, action) => {
    return produce(state, (draft) => {
        switch (action.type) {
            case GETCONVERSATIONS:
                draft.conversations = action.payload.processedTranscripts;
                draft.count = action.payload.totalCount;
                break;
            case CLEANALLCONVERSATIONS:
                draft.conversations = null;
                draft.count = null;
                break;
            case GETONETRANSCRIPT:
                draft.transcript = action.payload;
                break;
            case CLEANUPCONVERSATION:
                draft.transcript = null;
                break;
            case GETSALESTRANSCRIPTS: 
                draft.salesTranscripts = action.payload.transcripts;
                draft.salesTranscriptsCount = action.payload.count;
                break;
            case CLEANALLSALESCONVERSATIONS: 
                draft.salesTranscripts = null;
                draft.salesTranscriptsCount = null;
            default:
                break;
        }
    });
};
