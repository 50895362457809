import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    show: false,
    message: '',
    severity: 'info',
    
};

const snackBarSlice = createSlice({
    name: "snackBar",
    initialState,
    reducers: {
        showSnackBar: (state, action) => {
            state.show = true;
            state.message = action.payload.message || '';
            state.severity = action.payload.severity;
            console.log(state.message);
            
        },
        hideSnackBar: (state) => {
            state.show = false;
            state.message = '';
            state.severity = 'info';
            
        }
    }
});

export const { showSnackBar, hideSnackBar } = snackBarSlice.actions;
export default snackBarSlice.reducer;
