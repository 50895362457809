import axios from '../../../axios'

export const GETALLLEADS = 'GETALLLEADS'
export const CLEANUPALLLEADS = 'CLEANUPALLLEADS'

export const getAllLeads = (filters) => {
    return (dispatch) => {
        axios.get(`/leads/sales`, { params: filters }).then((res) => {
            dispatch({
                type: GETALLLEADS,
                payload: res.data
            })
        }).catch((error) => {
            console.log(error)
        })
    }
}

export const cleanUpAllLeads = () => {
    return {
        type: CLEANUPALLLEADS
    }
}