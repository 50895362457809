import axios from '../../../axios'

export const GETALLAGENTS = 'GETALLAGENTS'
export const CLEANUPALLAGENTS = 'CLEANUPALLAGENTS'

export const getAllAgents = (filters) => {
    return (dispatch) => {
        axios.get(`/agents/sales`, { params: filters }).then((res) => {
            dispatch({
                type: GETALLAGENTS,
                payload: res.data
            })
        }).catch((error) => {
            console.log(error)
        })
    }
}

export const cleanUpAllAgents = () => {
    return {
        type: CLEANUPALLAGENTS
    }
}