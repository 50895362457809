import { produce } from "immer";
import { CLEANUPALLLEADS, GETALLLEADS } from "redux/actions/leadsAction";

const initialState = {
    allLeads: null,
    count: null
};

export const leadsReducer = (state = initialState, action) => {
    return produce(state, (draft) => {
        switch (action.type) {
            case GETALLLEADS:
                draft.allLeads = action.payload.data
                // draft.count = action.payload
                break;
            case CLEANUPALLLEADS: 
                draft.allLeads = null    
            default:
                break;
        }
    });
};
